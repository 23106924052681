import { Typography, Card, CardHeader, CardBody, CardFooter, Carousel } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowLeft, MdOutlineFileDownload } from 'react-icons/md';

import points from '../images/point group.png';
import person from '../images/who.jpeg';

import book from '../images/home/icons/1.png';
import list from '../images/home/icons/2.png';
import enter from '../images/home/icons/3.png';
import messages from '../images/home/icons/4.png';
import money from '../images/home/icons/5.png';
import pen from '../images/home/icons/6.png';

import next from '../images/home/slider/next.png';
import prev from '../images/home/slider/prev.png';
import man from '../images/home/slider/man.png';

import mark1 from '../images/home/slider/1.png';
import mark2 from '../images/home/slider/2.png';
import mark3 from '../images/home/slider/3.png';

import slide1 from '../images/home/slider/slides/slide1.png';
import slide2 from '../images/home/slider/slides/slide2.png';
import slide3 from '../images/home/slider/slides/slide3.png';
import slide4 from '../images/home/slider/slides/slide4.png';
import slide5 from '../images/home/slider/slides/slide5.png';

import banner from '../images/home/banner.png';

import arrow from '../images/home/arrow.png';
import arrow_down from '../images/home/arrow down.png';
import arrow_left from '../images/home/arrow left.png';
import { Link } from 'react-router-dom';


const Home = () => {

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
  const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [isMessageEmpty, setIsMessageEmpty] = useState(false);



  const validateInputs = () => {
    setIsFirstnameEmpty(firstname.trim() === '');
    setIsLastnameEmpty(lastname.trim() === '');
    setIsPhoneEmpty(phone.trim() === '');
    setIsEmailEmpty(email.trim() === '');
    setIsAddressEmpty(address.trim() === '');
    setIsMessageEmpty(message.trim() === '');
    return !isFirstnameEmpty && !isLastnameEmpty && !isPhoneEmpty && !isEmailEmpty && !isAddressEmpty && !isMessageEmpty;
  };



  const [heightStyle, setHeightStyle] = useState({});

  useEffect(() => {
    const updateHeightStyle = () => {
      const isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
      const height = isMobile ? '100vh' : 'calc(100vh - 150px)';
      setHeightStyle({ height: height });
    };

    updateHeightStyle();

    window.addEventListener('resize', updateHeightStyle);
    return () => window.removeEventListener('resize', updateHeightStyle);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId, marginTop = 100) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - marginTop,
        behavior: "smooth",
      });
    }
  };


  return (
    <div>

      {/* landing */}
      <section
        // style={heightStyle}
        className='w-full relative h-screen'
      >
        <Carousel
          loop
          dir='ltr'

          className="rounded-none z-10"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-16 left-2/4 z-50 flex -translate-x-2/4 gap-2" dir='rtl'>
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-2 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-10 bg-gradient-to-r from-info via-secondary to-primary" : "w-2 bg-[#7ADDD8]/50"
                    }`}
                  onClick={() => { setActiveIndex(i); console.log(length); }}
                />
              ))}
            </div>
          )}
          nextArrow={({ loop, handleNext, lastIndex }) => (
            <button
              onClick={handleNext}
              disabled={!loop && lastIndex}
              className="!absolute top-[60%] left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center"
            >
              <img src={prev} alt="Next" className='h-10 w-10' />
            </button>
          )}

          prevArrow={({ loop, handlePrev, firstIndex }) => (
            <button
              onClick={handlePrev}
              disabled={!loop && firstIndex}
              className="!absolute top-[60%] right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center"
            >
              <img src={next} alt="Prev" className='h-10 w-10' />
            </button>
          )}
        >



          <div className="h-full w-full flex md:flex-row flex-col items-center justify-center md:bg-center bg-right pt-44" dir='rtl' style={{ backgroundImage: `url(${slide1})`, backgroundSize: 'cover' }}>
            <div className='h-full md:w-1/2 w-full'></div>
            <div className='h-full md:w-1/2 w-full p-4 flex flex-col justify-center'>

              <div className='md:w-3/4 mb-2'>
                <Typography className='text-primary md:text-4xl font-bold text-2xl mb-4'>
                  الملف التعريفي لبرنامج العمـــــل عــــــــن بعــــــــــــــــد
                </Typography>

                <Typography className='text-text md:text-xl font-medium text-lg md:mb-2 mb-4'>
                  تــعرف على أهم تفاصيل الخدمة عبر الإطلاع علـــــــــــى الملــــــــف التعريفــــــــي
                </Typography>
              </div>

              <button className="flex items-center rounded-xl bg-gradient-to-r from-info via-secondary to-primary p-2 hover:animate-bounce w-fit">
                <Typography className="text-white font-semibold mx-2">
                  تحميل
                </Typography>
                <div className="h-6 border border-white"></div>
                <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
              </button>
            </div>
          </div>

          <div className="h-full w-full flex md:flex-row flex-col items-center justify-center pt-44" dir='rtl' style={{ backgroundImage: `url(${slide3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='h-full w-full p-4 flex flex-col justify-center items-center'>
              <Typography className='text-primary md:text-4xl font-bold text-2xl mb-4 text-center'>
                أطلب تجربة النظام والتحدث مع فريقنا الآن
              </Typography>

              <button className="flex items-center rounded-xl bg-gradient-to-r from-info via-secondary to-primary p-2 hover:animate-bounce w-fit mt-4">
                <Typography className="text-white font-semibold mx-2">
                  تواصل معنا
                </Typography>
              </button>
            </div>
          </div>


          <div className="h-full w-full flex md:flex-row flex-col items-center justify-center md:bg-center bg-right pt-44" dir='rtl' style={{ backgroundImage: `url(${slide4})`, backgroundSize: 'cover' }}>
            <div className='h-full md:w-1/2 w-full'></div>
            <div className='h-full md:w-1/2 w-full p-4 flex flex-col justify-center'>
              <div className='md:w-4/5 w-full'>
                <Typography className='text-primary md:text-4xl font-bold text-2xl mb-4'>
                  عن شركة موارد الشرق الأوسط
                </Typography>

                <Typography className='text-text md:text-xl font-medium text-lg md:mb-2 mb-4'>
                  مزود خدمة معتمد لبرنامج العمل عن بعد ومن أكثر الجهات موثوقية لمنشآت القطاع الخاص نظراً للخبرة الممتدة لسنوات في تقديم خدمة العمل عن بعد
                </Typography>
              </div>
            </div>
          </div>

        </Carousel>



        <div className='absolute -bottom-10 inset-x-0 w-full z-10 md:px-0 px-4'>
          <div className='flex items-center justify-evenly md:w-2/3 w-full md:mx-auto p-4 rounded-3xl bg-gradient-to-r from-primary via-secondary to-info'>
            <img src={mark1} alt='mark1' className='md:h-14 h-10' />
            <div className="md:h-14 h-10 border border-white"></div>
            <img src={mark2} alt='mark2' className='md:h-14 h-10' />
            <div className="md:h-14 h-10 border border-white"></div>
            <img src={mark3} alt='mark3' className='md:h-14 h-10' />
          </div>
        </div>

        <img src={arrow} alt='arrow' className='absolute w-1/2 -top-6 -left-52 z-0' />

      </section>

      {/* facilities */}
      <section className='bg-white' id="facilitiesSection">
        <div className="relative container mx-auto pt-16 pb-20">
          <div className='p-2 relative'>
            <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
              سهولـــة الوصول
            </Typography>
            <div className='sm:h-6 h-4 sm:w-72 w-52 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 z-10">

            <Card shadow={false} className='bg-gradient-to-br from-primary to-info rounded-3xl flex flex-col justify-between z-10 group hover:bg-gradient-to-br hover:from-primary hover:to-primary hover:scale-105 transition duration-300'>
              <CardBody className='flex flex-col justify-between'>
                <div className='flex justify-between items-center pb-4'>
                  <Typography variant='lead' className='text-[#F1FBE7] font-bold ml-2'>
                    المكتبة الاسترشادية
                  </Typography>
                  <img src={book} alt='book' className='w-12' />
                </div>
              </CardBody>
              <CardFooter className='pt-0'>
                <div className='w-full flex justify-center'>
                  <Link
                    to='/library'
                    style={{ boxShadow: "0px 12px 0px -5px rgba(241, 251, 231, 0.5)", borderRadius: 10 }}
                    className="w-2/3 flex items-center justify-center rounded-xl bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60 group-hover:bg-info">
                    <Typography className="text-primary group-hover:text-white font-semibold mr-2">
                      المزيد
                    </Typography>
                    <MdKeyboardArrowLeft className="text-primary group-hover:text-white ml-2 mr-1 text-xl" />
                  </Link>
                </div>
              </CardFooter>
            </Card>


            <Card shadow={false} className='bg-gradient-to-br from-primary to-info rounded-3xl flex flex-col justify-between z-10 group hover:bg-gradient-to-br hover:from-primary hover:to-primary hover:scale-105 transition duration-300'>
              <CardBody className='flex flex-col justify-between'>
                <div className='flex justify-between items-center'>
                  <Typography variant='lead' className='text-[#F1FBE7] font-bold ml-2'>
                    لائحة عمل برنامج العمل عن بعد
                  </Typography>
                  <img src={list} alt='list check' className='w-12' />
                </div>
              </CardBody>
              <CardFooter className='pt-0'>
                <div className='w-full flex justify-center'>
                  <Link
                    to='/about'
                    style={{ boxShadow: "0px 12px 0px -5px rgba(241, 251, 231, 0.5)", borderRadius: 10 }}
                    className="w-2/3 flex items-center justify-center rounded-xl bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60 group-hover:bg-info">
                    <Typography className="text-primary group-hover:text-white font-semibold mr-2">
                      المزيد
                    </Typography>
                    <MdKeyboardArrowLeft className="text-primary group-hover:text-white ml-2 mr-1 text-xl" />
                  </Link>
                </div>
              </CardFooter>
            </Card>


            <Card shadow={false} className='bg-gradient-to-br from-primary to-info rounded-3xl flex flex-col justify-between z-10 group hover:bg-gradient-to-br hover:from-primary hover:to-primary hover:scale-105 transition duration-300'>
              <CardBody className='flex flex-col justify-between'>
                <div className='flex justify-between items-center pb-4'>
                  <Typography variant='lead' className='text-[#F1FBE7] font-bold ml-2'>
                    عروض الاسعار
                  </Typography>
                  <img src={money} alt='money' className='w-12' />
                </div>
              </CardBody>
              <CardFooter className='pt-0'>
                <div className='w-full flex justify-center'>
                  <button
                    onClick={() => scrollToSection('pricesSection')}
                    style={{ boxShadow: "0px 12px 0px -5px rgba(241, 251, 231, 0.5)", borderRadius: 10 }}
                    className="w-2/3 flex items-center justify-center rounded-xl bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60 group-hover:bg-info">
                    <Typography className="text-primary group-hover:text-white font-semibold mr-2">
                      المزيد
                    </Typography>
                    <MdKeyboardArrowLeft className="text-primary group-hover:text-white ml-2 mr-1 text-xl" />
                  </button>
                </div>
              </CardFooter>
            </Card>

          </div>

          <img src={arrow} alt='arrow' className='absolute w-1/2 top-0 -left-52 z-0' />
          <img src={arrow_down} alt='arrow down' className='absolute w-1/2 bottom-0 -right-52 z-0' />

        </div>

      </section>



      {/* who are we */}
      <section className="container mx-auto flex md:flex-row flex-col items-center pt-10 pb-20">

        <div className='md:w-1/2 w-full md:px-4 md:py-0 py-4'>
          {/* title and line */}
          <div className='flex mb-4'>
            <Typography variant='h4' className='text-primary font-semibold'>
              من نحن
            </Typography>
            <div className="border-b-2 mb-2 mr-2 border-primary flex-grow"></div>
          </div>

          <Typography variant='lead' className='text-text'>
            تعد شركة موارد الشرق الأوسط من أوائل مزودي الخدمة ومن الجهات الأكثر موثوقية لمنشآت القطاع الخاص وذلك لخبرتها الممتدة لسنوات في تقديم خدمة العمل عن بعد.
            تقدم شركة موارد الشرق الأوسط عبر فروعها خدمات التوظيف للموارد البشرية بكل إتقان والكثير من النظام الذكية لإدارتهم.
            يؤمن القائمين على شركة موارد الشرق الأوسط على أهمية بناء شراكة ناجحة من العملاء والعمل لتحقيق مصلحة العميل وتقديم كافة الخدمات المساندة التي يطلبها العمل, لذا فإن كافة الخدمات الاستشارية تقدم مجانا وتشمل المكتبة الاسترشادية وتوصيف الوظائف المرتبطة ببرنامج العمل عن بعد إضافة إلى خدمات التوظيف ومصادر الاستقطاب وبرامج الدعم وغيرها.
          </Typography>

        </div>


        <div className='relative md:w-1/2 w-full flex justify-center items-center'>
          <div className='relative md:w-4/5 w-full '>

            <div className='flex items-center'>
              <img src={person} alt='who' className='rounded-3xl' />
            </div>
            <img src={points} alt='points' className='absolute -top-[5%] -left-[5%] w-24' />
            <img src={points} alt='points' className='absolute -bottom-[10%] right-[25%] w-24' />
          </div>
        </div>
      </section>


      {/* steps */}
      <section className=" bg-[#F6F6F6]">
        <div className='relative container mx-auto pt-10 pb-20'>
          <div className='p-2 relative'>
            <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
              خطوات العمل عن بعد
            </Typography>
            <div className='sm:h-6 h-4 sm:w-96 w-60 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10">


            <Card shadow={false} className='bg-[#F1FBE7] hover:bg-gradient-to-br hover:from-primary hover:to-info flex flex-col justify-between md:rounded-r-3xl md:rounded-l-none rounded-3xl group'>
              <CardBody className='flex flex-col pt-4'>
                <Typography className='mb-2 text-[#DDE7D3] text-8xl font-bold group-hover:text-[#C4E4A5]'>
                  01
                </Typography>
                <Typography variant='paragraph' className='text-text group-hover:text-[#F1FBE7] font-medium'>
                  انشاء العقد مع مزود الخدمة المعتمد على بوابة العمل عن بعد
                </Typography>
              </CardBody>
              <div className='md:self-end self-center mx-1 w-4/5' style={{ height: '5%', width: '80%', borderTop: '4px solid #F1FBE7' }}></div>
              <div className="border-b-4 mx-1 w-4/5 border-[#7ADDD8] md:self-end self-center group-hover:hidden"></div>
            </Card>


            <Card shadow={false} className='bg-[#F1FBE7] hover:bg-gradient-to-br hover:from-primary hover:to-info flex flex-col justify-between rounded-none hover:rounded-3xl group'>
              <CardBody className='flex flex-col pt-4'>
                <Typography className='mb-2 text-[#DDE7D3] text-8xl font-bold group-hover:text-[#C4E4A5]'>
                  02
                </Typography>
                <Typography variant='paragraph' className='text-text group-hover:text-[#F1FBE7] font-medium'>
                  اعلن عن الوظيفة و استقبل طالبين العمل
                </Typography>
              </CardBody>
              <div className='self-center' style={{ height: '5%', width: '80%', borderTop: '4px solid #F1FBE7' }}></div>
              <div className="border-b-4 mx-1 border-[#7ADDD8] group-hover:hidden"></div>
            </Card>

            <Card shadow={false} className='bg-[#F1FBE7] hover:bg-gradient-to-br hover:from-primary hover:to-info flex flex-col justify-between rounded-none hover:rounded-3xl group'>
              <CardBody className='flex flex-col pt-4'>
                <Typography className='mb-2 text-[#DDE7D3] text-8xl font-bold group-hover:text-[#C4E4A5]'>
                  03
                </Typography>
                <Typography variant='paragraph' className='text-text group-hover:text-[#F1FBE7] font-medium'>
                  انشاء عقد العمل مع المرشح المناسب
                </Typography>
              </CardBody>
              <div className='self-center' style={{ height: '5%', width: '80%', borderTop: '4px solid #F1FBE7' }}></div>
              <div className="border-b-4 mx-1 border-[#7ADDD8] group-hover:hidden"></div>
            </Card>


            <Card shadow={false} className='bg-[#F1FBE7] hover:bg-gradient-to-br hover:from-primary hover:to-info flex flex-col justify-between md:rounded-l-3xl md:rounded-r-none rounded-3xl group'>
              <CardBody className='flex flex-col pt-4'>
                <Typography className='mb-2 text-[#DDE7D3] text-8xl font-bold group-hover:text-[#C4E4A5]'>
                  04
                </Typography>
                <Typography variant='paragraph' className='text-text group-hover:text-[#F1FBE7] font-medium'>
                  ابدا العمل على نظام موارد لادارة العاملين عن بعد
                </Typography>
              </CardBody>
              <div className='md:self-start self-center mx-1 w-4/5' style={{ height: '5%', width: '80%', borderTop: '4px solid #F1FBE7' }}></div>
              <div className="border-b-4 mx-1 w-4/5 border-[#7ADDD8] md:self-start self-center group-hover:hidden"></div>
            </Card>
          </div>

          <img src={arrow_left} alt='arrow left' className='absolute w-1/2 -top-1/2 -right-[40%] z-0' />


        </div>
      </section>


      {/* questions */}
      <section className="md:px-48 px-10 py-10 " style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover' }}>
        <div className='p-2 relative'>
          <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-[#F1FBE7] text-center'>
            الأسئلة الشائعة
          </Typography>
        </div>

        <div className="mt-5 flex flex-col items-center">
          <Typography variant='lead' className='text-white text-center'>
            قمنا بتجميع أسئلة العملاء الشائعة ووضعها في صفحة الأسئلة المتكررة، ويسرنا الإجابة على أي استفسارات تخطر في بالك في أي وقت
          </Typography>

          <Link to="/questions" className="flex items-center rounded-xl mt-5 bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <Typography className="text-primary font-semibold mr-2">
              اضغط هنا للإنتقال لصفحة الاسئلة
            </Typography>
            <MdKeyboardArrowLeft className="text-primary ml-2 mr-1 text-xl" />
          </Link>
        </div>
      </section>



      {/* statistics */}
      <section className="md:px-48 px-10 pt-10 pb-20">

        <div className='p-2 relative'>
          <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
            حصائيات نظام موارد إلدارة العاملين عن بعد
          </Typography>
          <div className='sm:h-6 h-4 sm:w-[680px] w-52 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-10">
          <div className='flex flex-col items-center'>
            <Typography variant='h1' className='mb-4 text-info font-bold'>
              1000 +
            </Typography>
            <Typography variant='lead' className=' text-text text-center'>
              مستخــدم نــشط يوميــاً على منصــة مــوارد
            </Typography>
          </div>


          <div className='flex flex-col items-center py-4 md:py-0 lg:py-0 md:px-4 lg:px-4 md:border-r border-r-0 border-info'>
            <Typography variant='h1' className='mb-4 text-info font-bold'>
              400 +
            </Typography>
            <Typography variant='lead' className=' text-text text-center'>
              مرشح متخصص جاهز للعمل ضمن قاعدة بياناتنا
            </Typography>
          </div>
        </div>
      </section>


      {/* prices */}
      <section className="md:px-48 px-10 pt-10 pb-20 bg-white" id="pricesSection">

        <div className='p-2 relative'>
          <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
            الأسعار
          </Typography>
          <div className='sm:h-6 h-4 sm:w-36 w-28 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">

          <Card shadow={false} className='bg-[#F1FBE7] hover:bg-primary group hover:scale-105 transition duration-300'>
            <CardHeader shadow={false} floated={false} className='px-4 pt-4 m-0 bg-transparent rounded-none border-b border-[#7ADDD8] group-hover:border-[#F1FBE7]'>
              <Typography variant='h3' className='mb-4 text-primary group-hover:text-[#F1FBE7] text-center p-0'>
                الباقة الاساسية
              </Typography>
            </CardHeader>
            <CardBody className='flex flex-col justify-center flex-1 pt-4'>

              <ul className='list-disc text-text group-hover:text-[#F1FBE7] px-4'>
                <li>مدة التعاقد بالباقة سنتين بدون رسوم تأسيس</li>
                <li>تسجيل الموظفين والموظفات في بوابة العمل عن بعد التابعة لوزارة العمل و التنمية الإجتماعية</li>
                <li>تفعيل منصة موارد الإلكترونية</li>
                <li>دعم فني على مدار الساعة</li>
              </ul>
              <div></div>

            </CardBody>
            <CardFooter>
              <div className='w-full flex justify-center'>
                <Link
                  to='/register'
                  style={{ boxShadow: "0px 12px 0px -5px rgba(241, 251, 231, 0.5)", borderRadius: 10 }}
                  className="w-2/3 flex justify-center items-center rounded-xl bg-[#7ADDD8] group-hover:bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                  <Typography className="text-white group-hover:text-primary font-semibold mr-2">
                    اشترك
                  </Typography>
                  <MdKeyboardArrowLeft className="text-white group-hover:text-primary ml-2 mr-1 text-xl" />
                </Link>
              </div>
            </CardFooter>
          </Card>

          <Card shadow={false} className='bg-[#F1FBE7] hover:bg-primary group hover:scale-105 transition duration-300'>
            <CardHeader shadow={false} floated={false} className='px-4 pt-4 m-0 bg-transparent rounded-none border-b border-[#7ADDD8] group-hover:border-[#F1FBE7]'>
              <Typography variant='h3' className='mb-4 text-primary group-hover:text-[#F1FBE7] text-center p-0'>
                باقة المساندة
              </Typography>
            </CardHeader>
            <CardBody className='flex flex-col justify-center pt-4'>
              <ul className='list-disc text-text group-hover:text-[#F1FBE7] px-4'>
                <li>مدة التعاقد بالباقة سنتين ميلادية
                  بدون رسوم تأسيس
                </li>
                <li>تسجيل الموظفين والموظفات في بوابة العمل عن بعد التابعة لوزارة العمل والتنمية الإجتماعية</li>
                <li>تفعيل منصة موارد الإلكترونية</li>
                <li>تدريب الموظفين عن بعد والمشرفين من قبل صاحب العمل على آلية عمل منصة موارد الإلكترونية</li>
                <li>تقديم الخدمات الإستشارية والخطط التوظيفية المسانده لاحتياجات المنشأة</li>
                <li>إدارة كاملة لعملية الاستقطاب والتوظيف  وحتى إتمام</li>
                <li>التعاقد من  خلال شركة الريادة الوطنية للتوظيف</li>
                <li>تفعيل نظام موارد لمتابعة الحضور والإنصراف وتقييم الآداء للموظفين عن بعد</li>
                <li>إرسال إشعارات دورية لزيادة إنتاجية العمل تقديم حقيبة</li>
                <li>تدريبية متكاملة عن طبيعة العمل عن بعد للموظفين</li>
                <li>دعم فني على مدار الساعة 7/24</li>
              </ul>
            </CardBody>
            <CardFooter>
              <div className='w-full flex justify-center'>
                 <Link
                  to='/register'
                  style={{ boxShadow: "0px 12px 0px -5px rgba(241, 251, 231, 0.5)", borderRadius: 10 }}
                  className="w-2/3 flex justify-center items-center rounded-xl bg-[#7ADDD8] group-hover:bg-[#F1FBE7] p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                  <Typography className="text-white group-hover:text-primary font-semibold mr-2">
                    اشترك
                  </Typography>
                  <MdKeyboardArrowLeft className="text-white group-hover:text-primary ml-2 mr-1 text-xl" />
                </Link>
              </div>
            </CardFooter>
          </Card>

        </div>

      </section>

      {/* contact */}
      <section className="md:px-48 px-10 py-10 bg-[#E6E6E6]">

        <div className='p-2 relative'>
          <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
            اتصل بنا
          </Typography>
          <div className='sm:h-6 h-4 sm:w-36 w-28 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-info mt-10">
          <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-300'}`} />

          <input type='text' placeholder='الاسم' onChange={(e) => setFirstname(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-300'}`} />
          <input type='text' placeholder='اللقب' onChange={(e) => setLastname(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-300'}`} />

          <input type='text' placeholder='رقم الهاتف' onChange={(e) => setPhone(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-300'}`} />
          <input type='text' placeholder='العنوان' onChange={(e) => setAddress(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isAddressEmpty ? 'border-red-400' : 'border-gray-300'}`} />

          <textarea type='text' placeholder='الرسالة' onChange={(e) => setMessage(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 h-36 rounded-xl border-2 placeholder:font-light ${isMessageEmpty ? 'border-red-400' : 'border-gray-300'}`} />
        </div>

        <div className='w-full flex justify-end'>
          <button className="flex items-center rounded-xl px-5 bg-primary p-2 mt-5 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <Typography className="text-white font-semibold mr-2">
              ارسل
            </Typography>
            <MdKeyboardArrowLeft className="text-white ml-2 mr-1 text-xl" />
          </button>
        </div>

      </section>

    </div >
  )
}

export default Home