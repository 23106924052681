import { Accordion, AccordionBody, AccordionHeader, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';

const Questions = () => {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <div className='p-2 relative mt-44'>
                <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
                    الأسئلة الشائعة
                </Typography>
                <div className='sm:h-6 h-4 sm:w-72 w-64 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
            </div>


            <div className="mt-10 mb-20 container mx-auto">
                <Accordion open={open === 1} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 1 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={`${open === 1 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        ما هو العمل عن بعد؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 1 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        برنامج العمل عن بعد هو أحد المبادرات التي أطلقتها وزارة الموارد البشرية و التنمية الاجتماعية بهدف تجسير الفجوة بين أصحاب الأعمال والباحثين عنها
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 2} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 2 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(2)} className={`${open === 2 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        ما هي الفئات التي يمكنها الانضمام لبرنامج العمل عن بعد؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 2 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        برنامج العمل عن بعد متاح لجميع الفئات من الإناث والذكور وذوي الإعاقة.
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 3} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 3 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(3)} className={`${open === 3 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        ما هي شروط الإنضمام كعامل عن بعد واحتسابه في التوطين بنسبة 100% ؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 3 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        <ul className='list-disc px-3'>
                            <li>أن يكون سعودي الجنسية.</li>
                            <li>العمر من 18-60.</li>
                            <li>حساب أبشر مفعل.</li>
                            <li>إجادة استخدام التقنية الحديثة.</li>
                        </ul>
                    </AccordionBody>
                </Accordion>



                <Accordion open={open === 4} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 4 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(4)} className={`${open === 4 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        ما هي نوعية الوظائف المتاحة التي يمكن أن أعمل فيها عن بعد؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 4 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        أي وظيفة يمكن تأديتها عبر وسائل الاتصال وتقنية المعلومات ودون الحاجة للحضور لمقر العمل.
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 5} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 5 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(5)} className={`${open === 5 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        من هم مزودي الخدمات؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 5 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        هي شركات ﻣﻌﺘﻤﺪة وﻣﺘﻌﺎﻗﺪة ﻣﻊ وزارة الموارد البشرية واﻟﺘﻨﻤﻴﺔ اﻻﺟﺘﻤﺎﻋﻴﺔ ﻣﺘﺨﺼﺼﺔ ﻓﻲ ﻣﺠـﺎل ﺗﻘﺪﻳﻢ اﻟﺤﻠﻮل واﻟﺨﺪﻣـﺎت اﻟﺘﻘﻨﻴـﺔ واﻹﻟﻜﺘـﺮوﻧﻴـﺔ واﻟﻤﺴﺎﻧﺪة ﻟﻠﻌﻤﻞ ﻋﻦ ﺑﻌد, وﻟـﺪﻳـﻬـﺎ اﻟـﻜﻮادر واﻟـﺨﺒﺮات اﻟﻤﺆﻫﻠﺔ واﻟﻘادرة على إنشاء وتجهيز وتفعيل برنامج العمل بعد.
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 6} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 6 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(6)} className={`${open === 6 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        هل شركة موارد الشرق الأوسط مزود خدمة معتمد؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 6 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        نعم, ومن أوائل الشركات التي أعتمدت في البرنامج منذ عام 2016 ويعمل على نظامها الإلكتروني أكثر من 1000 حساب نشط بشكل يومي.
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 7} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 7 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(7)} className={`${open === 7 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        هل هنالك أي رسوم للتعاقد مع شركة موارد الشرق الأوسط كمزود خدمة؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 7 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        نعم، هنالك رسوم للتعاقد معها كمزود خدمة ولمعرفة الباقات المقدمة نسعد بالتواصل على هذا الرقم عبر الوتساب او الإتصال (0548008093)
                    </AccordionBody>
                </Accordion>

                <Accordion open={open === 8} className='mb-2' icon={<MdKeyboardArrowDown className={`${open === 8 ? "rotate-180" : ""} transition-transform text-text text-2xl`} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(8)} className={`${open === 8 ? "bg-gradient-to-br from-primary via-secondary to-info text-white rounded-t-lg" : "bg-white text-text rounded-lg"} border border-info px-4 py-3 text-right`}>
                        هل قدم شركة موارد الشرق الأوسط خدمات التوظيف؟
                    </AccordionHeader>
                    <AccordionBody className={`${open === 8 ? "rounded-b-lg" : "rounded-lg"} border border-info px-4 bg-white py-3 text-text`}>
                        نعم, وذلك عبر فرعها شركة الريادة الوطنية للتوظيف بشكل مجاني لعملاء لبرنامج العمل عن بعد.
                    </AccordionBody>
                </Accordion>
            </div>

        </div>
    )
}

export default Questions