import React, { useEffect, useState } from 'react';
import { Card, CardBody, Tab, Tabs, TabsHeader, Typography } from '@material-tailwind/react';

import img1 from '../images/footer/Groupe 81.png';
import img2 from '../images/footer/Groupe 82.png';
import img3 from '../images/footer/Groupe 83.png';
import img4 from '../images/footer/Groupe 84.png';

import logo from '../images/logo.png'
import background from '../images/loginback.png'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('company');

    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);


    const validateInputs = () => {
        setIsEmailEmpty(email.trim() === '');
        setIsPasswordEmpty(password.trim() === '');
        return !isEmailEmpty && !isPasswordEmpty;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='relative flex lg:flex-row md:flex-row flex-col bg-cover bg-center h-screen' style={{ backgroundImage: `url(${background})` }}>

            <div className='lg:w-[45%] md:w-[45%] w-full flex flex-col items-center justify-center bg-white lg:rounded-l-3xl md:rounded-l-3xl'>

                <div className='p-2 relative mt-10'>
                    <img src={logo} className='h-20' />
                </div>

                <div className='p-2 relative'>
                    <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
                        الدخول على نظام موارد
                    </Typography>
                    <div className='sm:h-6 h-4 sm:w-96 w-64 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                </div>


                <div>
                    <Tabs value={type} className="mt-8 container mx-auto">
                        <TabsHeader className='bg-white rounded-none p-0 max-w-full mx-auto gap-4' indicatorProps={{ className: 'shadow-none rounded-md bg-gradient-to-br from-secondary to-primary text-white' }}>
                            <Tab value="company" className={`${type === 'company' ? 'border-info text-white' : 'border-primary text-primary bg-white'} p-2 rounded-lg border-2 px-5`} onClick={() => setType('company')}>
                                منشآة
                            </Tab>
                            <Tab value="employee" className={`${type === 'employee' ? 'border-info text-white' : 'border-primary text-primary bg-white'} p-2 rounded-lg border-2 px-5`} onClick={() => setType('employee')}>
                                موظف
                            </Tab>
                        </TabsHeader>
                    </Tabs>
                </div>


                <div className='container mx-auto mb-10'>
                    <Card shadow={false}>
                        <CardBody className='flex justify-center'>

                            <div className="grid grid-cols-1 gap-4 text-primary lg:w-2/3 w-full">
                                <div className='flex flex-col col-span-1'>
                                    <Typography
                                        className='mr-2 mb-2 text-right'
                                        variant='paragraph'
                                    >
                                        البريد الإلكتروني
                                    </Typography>
                                    <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                                </div>

                                <div className='flex flex-col col-span-1'>
                                    <Typography
                                        className='mr-2 mb-2 text-right'
                                        variant='paragraph'
                                    >
                                        كلمة المرور
                                    </Typography>
                                    <input type='password' placeholder='كلمة المرور' onChange={(e) => setPassword(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isPasswordEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                                </div>

                                <div className='flex items-center justify-between mt-3'>

                                    <button className="flex items-center rounded-xl bg-gradient-to-r from-primary via-secondary to-info p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                                        <Typography className="text-white font-semibold mr-2">
                                            تسجيل الدخول
                                        </Typography>
                                    </button>

                                    <button className="flex items-center rounded-xl p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                                        <Typography className="text-text font-semibold mr-2">
                                            اعادة تعين كلمة المرور
                                        </Typography>
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </div>
            </div>


            <div className='relative lg:w-[55%] md:w-[55%] w-full h-full flex flex-col justify-center items-center lg:bg-transparent md:bg-transparent bg-primary'>
                <Typography variant='h1' className='text-[#F1FBE7]'>
                    مرحبا !
                </Typography>
                <Typography variant='h1' className='text-[#F1FBE7]'>
                    WELCOME !
                </Typography>

            </div>




            <div className="lg:absolute md:absolute lg:bg-transparent md:bg-transparent bg-white bottom-0 w-full p-3">
                <div className="flex flex-wrap lg:justify-between justify-center items-center">
                    <div className='sm:w-[45%] w-full'>
                        <div className="flex flex-wrap justify-center items-center">
                            <img src={img1} className="h-8 mx-2" alt="footer 1" />
                            <div className="h-8 border border-text mx-4"></div>
                            <img src={img2} className="h-8 mx-2" alt="footer 2" />
                            <img src={img4} className="h-8 mx-2" alt="footer 3" />
                            <img src={img3} className="h-8 mx-2" alt="footer 4" />
                        </div>
                    </div>

                    <div className='sm:w-[55%] w-full'>
                        <p className="text-center lg:text-white text-text md:mt-0 mt-4">
                            © 2024 جميع الحقوق محفوظة لـ موارد الشرق الأوسط
                        </p>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Login