import React, { useRef } from 'react';
import Header from './Header';
import Footer from './Footer';

import { Outlet } from 'react-router-dom';

const Layout = (props) => {

    const scrollToSection = (sectionId, marginTop = 100) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop - marginTop,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="overflow-hidden bg-[#F1FBE7]">
            <Header scrollToSection={scrollToSection} />
            <main>
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default Layout