import React, { useEffect, useState } from 'react';
import banner from '../images/contact.png';
import points from '../images/point group.png';
import { Typography } from '@material-tailwind/react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const Contact = () => {
    const [fullname, setFullname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [observation, setObservation] = useState('');

    const [isFullnameEmpty, setIsFullnameEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isObservationEmpty, setIsObservationEmpty] = useState(false);



    const validateInputs = () => {
        setIsFullnameEmpty(fullname.trim() === '');
        setIsCompanyEmpty(company.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsObservationEmpty(observation.trim() === '');
        return !isFullnameEmpty && !isCompanyEmpty && !isPhoneEmpty && !isEmailEmpty && !isObservationEmpty;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className='relative mt-[152px]'>
                <img src={banner} alt='contact banner' style={{
                    backgroundImage: 'linear-gradient(to bottom right, #55B9B7, #BBE1A6, #C4E4A5)' /* Gradient filter */
                }} />
                <div className='absolute top-10 left-20 sm:block hidden'>
                    <img src={points} alt='points top' />
                </div>

                <div className='absolute inset-0 flex flex-col justify-center items-center'>
                    <div className='p-2 relative'>
                        <Typography color='white' className='px-4 relative sm:text-4xl text-2xl font-semibold z-10'>
                            طلب استشارة
                        </Typography>
                        <div className='sm:h-6 h-4 sm:w-56 w-48 bg-primary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                    </div>
                </div>

                <div className='absolute bottom-10 right-20 sm:block hidden'>
                    <img src={points} alt='points bottom' />
                </div>
            </div>



            <div className="container mx-auto sm:w-2/3 py-10">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-info">
                    <input type='text' placeholder='المنشآة' onChange={(e) => setCompany(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 placeholder:font-light ${isCompanyEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    <input type='text' placeholder='اسم السؤول' onChange={(e) => setFullname(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isFullnameEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    <input type='text' placeholder='رقم التواصل' onChange={(e) => setPhone(e.target.value)} className={`px-3 py-2 rounded-xl border-2 placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-300'}`} />

                    <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    <textarea type='text' placeholder='ملاحظة' onChange={(e) => setObservation(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 h-36 rounded-xl border-2 placeholder:font-light ${isObservationEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                </div>

                <div className='w-full flex justify-center'>
                    <button className="flex items-center rounded-xl bg-gradient-to-r from-info via-secondary to-primary p-2 mt-5 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                        <Typography className="text-white font-semibold mr-2">
                            ارسل
                        </Typography>
                        <MdKeyboardArrowLeft className="text-white ml-2 mr-1 text-xl" />
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Contact