import React, { useEffect } from 'react';
import { Card, CardBody, CardFooter, Typography } from '@material-tailwind/react';
import { MdKeyboardArrowLeft, MdOutlineFileDownload } from 'react-icons/md';

const Library = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className='p-2 mt-44'>
                <div className='p-2 relative'>
                    <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
                        المكتبة الاسترشادية لبرنامج
                    </Typography>
                    <div className='sm:h-6 h-4 sm:w-[420px] w-72 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                </div>

                <div className='p-2 relative'>
                    <Typography color='white' className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
                        العمل عن بعد
                    </Typography>
                    <div className='sm:h-6 h-4 sm:w-56 w-40 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                </div>
            </div>

            <div className='container mx-auto'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card shadow={false} className='bg-white hover:bg-primary group hover:scale-105 transition duration-300'>
                        <CardBody className='flex flex-col justify-center text-center'>
                            <Typography variant='h3' className='mb-4 text-primary group-hover:text-info'>
                                مفهوم <br></br>
                                العمل
                                عن بعد
                            </Typography>
                            <Typography variant='lead' className='text-text group-hover:text-white'>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <div className='w-full flex justify-center'>
                                <button className="flex items-center rounded-xl bg-primary group-hover:bg-info p-2 hover:animate-bounce">
                                    <Typography className="text-white font-semibold mr-2">
                                        تحميل
                                    </Typography>
                                    <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                                </button>
                            </div>
                        </CardFooter>
                    </Card>
                </div>

                <div className='w-full flex justify-center'>
                    <button className="flex items-center rounded-xl bg-gradient-to-r from-info via-secondary to-primary p-2 mt-5 mb-10 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                        <Typography className="text-white font-semibold mr-2">
                            المزيد
                        </Typography>
                        <MdKeyboardArrowLeft className="text-white ml-2 mr-1 text-xl" />
                    </button>
                </div>

            </div>

        </div>
    )
}

export default Library