import React, { useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, Typography } from "@material-tailwind/react";

import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";

import { BsList } from "react-icons/bs";

import logo from '../images/logo.png'


const Header = ({ scrollToSection }) => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);


  const navList = (
    <ul className="mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center text-primary">
      <NavLink to={`/`}>
        {({ isActive }) => (
          <div>
            <Typography
              className={`${isActive && "bg-[#F2F2F2]"} px-4 py-5 font-bold`}
              variant="paragraph"
            >
              الرئيسية
            </Typography>
            {isActive && <div className="h-2 rounded w-full bg-gradient-to-r from-primary via-secondary to-info"></div>}
          </div>

        )}
      </NavLink>
      <Link
        to='/'
        onClick={() => scrollToSection("facilitiesSection")}
      >
        <div>
          <Typography
            className={`bg-transparent px-4 py-6 font-bold`}
            variant="paragraph"
          >
            سهولة الوصول
          </Typography>
        </div>
      </Link>


      <Link
        to='/'
        onClick={() => scrollToSection("pricesSection")}
      >
        <div>
          <Typography
            className={`bg-transparent px-4 py-6 font-bold`}
            variant="paragraph"
          >
            أسعار الخدمات
          </Typography>
        </div>
      </Link>


      <NavLink to={`questions`}>
        {({ isActive }) => (
          <div>
            <Typography
              className={`${isActive && "bg-[#F2F2F2]"} px-4 py-5 font-bold`}
              variant="paragraph"
            >
              الأسئلة الشائعة
            </Typography>
            {isActive && <div className="h-2 rounded w-full bg-gradient-to-r from-primary via-secondary to-info"></div>}
          </div>
        )}
      </NavLink>
      <NavLink to={`contact`}>
        {({ isActive }) => (
          <div>
            <Typography
              className={`${isActive && "bg-[#F2F2F2]"} px-4 py-5 font-bold`}
              variant="paragraph"
            >
              إتصل بنا
            </Typography>
            {isActive && <div className="h-2 rounded w-full bg-gradient-to-r from-primary via-secondary to-info"></div>}
          </div>
        )}
      </NavLink>
    </ul>
  );

  return (
    <div className="px-4 mt-4 z-50 fixed w-full">
      <div className="flex justify-between items-center rounded-2xl bg-gradient-to-br from-primary via-secondary to-info px-4 py-2">

        <div className="flex items-center gap-2">
          <div className="border-2 rounded-full border-white p-1 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <FaLinkedinIn className="text-lg text-white" />
          </div>
          <div className="border-2 rounded-full border-white p-1 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <FaTwitter className="text-lg text-white" />
          </div>
          <div className="border-2 rounded-full border-white p-1 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <FaFacebookF className="text-lg text-white" />
          </div>
        </div>

        <div className="flex items-center">
          <button className="z-50 flex items-center rounded-xl bg-transparent p-1 border transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <MdKeyboardArrowDown className="text-white ml-2 mr-1 text-lg" />
            <div className="h-6 border border-white"></div>
            <Typography color="white" className="font-semibold mx-2">
              العربية
            </Typography>
          </button>
          <div className="h-8 border border-info mx-4 sm:block hidden"></div>
          <Link to="/contact" className="z-50 hidden sm:flex items-center rounded-xl bg-white p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
            <GoPencil className="text-primary ml-2" />
            <div className="h-6 border border-primary"></div>
            <Typography className="font-bold mr-2">
              تحدث معنا الآن
            </Typography>
          </Link>
        </div>

      </div>

      <Navbar
        fullWidth
        shadow={false}
        variant="filled"
        color="white"
        className="py-0 rounded-2xl border-0"
      >

        <div className="xl:container mx-auto flex items-center justify-between h-full">
          <div className="flex items-center text-primary">
            <div className="lg:hidden block" onClick={() => setOpenNav(!openNav)}>
              <BsList className="text-3xl text-primary" />
            </div>
            <div className="lg:block hidden" onClick={() => setOpenNav(!openNav)}>
              <img src={logo} className="h-12" alt="logo" />
            </div>
          </div>

          <div className="hidden lg:block">{navList}</div>

          <div className="flex items-center my-4">
            <Link to='login' className="hidden sm:flex items-center rounded-xl bg-gradient-to-br from-info to-primary p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
              <Typography className="font-bold mr-2">
                طلب التوظيف للمنشآت
              </Typography>
            </Link>
            <a href="/login" className="flex items-center rounded-xl border border-primary p-2 mr-4 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
              <Typography className="font-bold mr-2 text-primary">
                الدخول على النظام
              </Typography>
            </a>

          </div>
        </div>

        <Collapse open={openNav}>
          <div className="text-center">
            {navList}
          </div>
        </Collapse>
      </Navbar>
      {/* <hr className="border-primary mx-8"></hr> */}
    </div>
  );
};

export default Header;
