import { Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

import img1 from '../images/footer/Groupe 81.png';
import img2 from '../images/footer/Groupe 82.png';
import img3 from '../images/footer/Groupe 83.png';
import img4 from '../images/footer/Groupe 84.png';

import { MdKeyboardArrowLeft, MdOutlinePhoneInTalk, MdLocationPin, MdKeyboardArrowDown } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { GoPencil } from "react-icons/go";



const Footer = () => {
  return (
    <footer className="bg-primary text-white">
      <div>
        <div className="container mx-auto grid md:grid-cols-2 grid-cols-1 gap-4 py-12 border-b border-b-info">

          <div className="flex flex-col">
            <Link to={`/`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                الرئيسية
              </Typography>
            </Link>

            <Link to={`/shop`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                البرنامج
              </Typography>
            </Link>

            <Link to={`/shop`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                الأسعار
              </Typography>
            </Link>

            <Link to={`/about`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                خطوات العمل عن بعد
              </Typography>
            </Link>

            <Link to={`/about`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                الأسئلة الشائعة
              </Typography>
            </Link>


            <Link to={`/about`}>
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                لماذا نحن؟
              </Typography>
            </Link>
          </div>


          {/* <div className="flex flex-col">
            <Typography
              className={`px-2 text-right`}
              variant="h4"
            >
              اشترك في <br></br>
              القائمة البريدية
            </Typography>

            <Typography
              className={`px-2 text-right`}
              variant="paragraph"
            >
              اشترك في القائمة البريدية ليصلك الجديد
            </Typography>

            <div className="flex items-center justify-between rounded-xl border-2 border-info bg-white bg-opacity-30 mt-4">
              <input placeholder="البريد الإلكتروني" type="email" className="h-full bg-transparent placeholder:text-white px-2 py-0 border-0 rounded-xl flex-1" />
              <button className="flex items-center rounded-[10px] bg-white p-1 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                <Typography className="text-primary font-semibold mr-2">
                  اشترك
                </Typography>
                <MdKeyboardArrowLeft className="text-primary ml-2 mr-1 text-lg" />
              </button>
            </div>

          </div> */}

          <div className="flex flex-col">
            <div className="flex items-center md:justify-end justify-start gap-2 mb-4">
              <div className="border-2 rounded-full border-white p-1">
                <FaFacebookF className="text-lg text-white" />
              </div>
              <div className="border-2 rounded-full border-white p-1">
                <FaTwitter className="text-lg text-white" />
              </div>
              <div className="border-2 rounded-full border-white p-1">
                <FaLinkedinIn className="text-lg text-white" />
              </div>
            </div>


            <div className="flex items-center md:justify-end justify-start">
              <Typography
                className={`px-2`}
                variant="paragraph"
              >
                +966 55 0897 496
              </Typography>
              <MdOutlinePhoneInTalk className="text-xl text-white" />
            </div>

            <div className="flex items-start md:justify-end md:text-left text-right justify-start">
              <Typography
                className={`px-2 md:w-2/3 w-full`}
                variant="paragraph"
              >
                14 و 13 الدائري الشرقي بين مخرجي في الجهة الغربية، الربوة، الرياض
              </Typography>
              <MdLocationPin className="text-xl text-white" />
            </div>
          </div>
        </div>


        <div className="container mx-auto flex items-center justify-between py-4">
          <Typography
            className={`px-2`}
            variant="paragraph"
          >
            الشروط | لخصوصية
          </Typography>

          <div className="flex items-center">
            <button className="flex items-center rounded-xl bg-transparent p-1 border transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
              <MdKeyboardArrowDown className="text-white ml-2 mr-1 text-lg" />
              <div className="h-6 border border-white"></div>
              <Typography color="white" className="font-semibold mx-2">
                العربية
              </Typography>
            </button>

            <div className="h-8 border border-info mx-4"></div>

            <Link to="/contact" className="flex items-center rounded-xl bg-white p-2 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
              <GoPencil className="text-primary ml-2" />
              <div className="h-6 border border-primary"></div>
              <Typography className="text-primary font-bold mr-2">
                تحدث معنا الآن
              </Typography>
            </Link>
          </div>
        </div>

        <hr className="border-info" />
        <div className="bg-white w-full p-3">

          <div className="container mx-auto flex flex-wrap lg:justify-between justify-center items-center">
            <div className="flex flex-wrap justify-center items-center">
              <img src={img1} className="h-8 mx-2" alt="footer 1" />
              <div className="h-8 border border-text mx-4"></div>
              <img src={img2} className="h-8 mx-2" alt="footer 2" />
              <img src={img4} className="h-8 mx-2" alt="footer 3" />
              <img src={img3} className="h-8 mx-2" alt="footer 4" />
            </div>
            <p className="text-center text-text md:mt-0 mt-4">
              © 2024 جميع الحقوق محفوظة لـ موارد الشرق الأوسط
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
