import React, { useEffect } from 'react';
import banner from '../images/about.png';
import points from '../images/point group.png';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { MdOutlineFileDownload } from 'react-icons/md';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <div className='relative mt-[152px]'>
                <img src={banner} alt='about banner' style={{
                    backgroundImage: 'linear-gradient(to bottom right, #55B9B7, #BBE1A6, #C4E4A5)'
                }} />
                <div className='absolute top-10 left-20 sm:block hidden'>
                    <img src={points} alt='points top' />
                </div>

                <div className='absolute inset-0 flex flex-col justify-center items-center'>
                    <div className='p-2 relative'>
                        <Typography color='white' className='px-4 relative sm:text-4xl text-2xl font-semibold z-10'>
                            لائحة عمل برنامج
                        </Typography>
                        <div className='sm:h-6 h-4 sm:w-64 w-48 bg-primary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                    </div>

                    <div className='p-2 relative'>
                        <Typography color='white' className='px-4 relative sm:text-4xl text-2xl font-semibold z-10'>
                            العمل عن بعد
                        </Typography>
                        <div className='sm:h-6 h-4 sm:w-56 w-40 bg-primary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                    </div>
                </div>

                <div className='absolute bottom-10 right-20 sm:block hidden'>
                    <img src={points} alt='points bottom' />
                </div>
            </div>



            <div className='container mx-auto mt-20'>
                <Card shadow={false} className='border-2 border-info'>
                    <CardBody className="text-right">

                        {/* part 1 */}
                        <Typography variant="h4" className="text-primary mb-2">
                            المقدمة والهدف من الدليل
                        </Typography>
                        <Typography className="font-medium text-text mb-4">
                            حرصًا من وزارة الموارد البشرية والتنمية الاجتماعية على توفير فرص عمل محفزة ومنتجة ومستقرة للمواطنين والمواطنات في مختلف مناطق المملكة، ورفع مستوى مشاركتهم في سوق العمل، وبناءً على توجه الوزارة في توطين القطاعات واستهداف المهن وتبني نمط العمل عن بعد وفقًا لأهداف رؤية 2030، وإشارة للقرار الوزاري رقم (792) وتاريخ (12/02/1436هـ) والقرار الوزاري رقم (120453) وتاريخ (28/12/1438هـ) والقرار الوزاري رقم (101329) وتاريخ (01/06/1442هـ) بشأن قرار إحتساب العاملين عن بعد في نطاقات لتمكين الشباب والشابات من فرص العمل في القطاع الخاص.

                        </Typography>
                        <Typography className="font-medium text-text mb-4">
                            وحيث يعتبر هذا القرار ضمن مجموعة من القرارات التنظيمية التي عملت عليها الوزارة لتوفير فرص عمل محفزة للموارد البشرية الوطنية، وتوسيع دائرة المشاركة مع القطاع الخاص المتوافقة مع استراتيجية الوزارة المعنية بتنمية رأس المال البشري، وتعزيز المساهمة في المنظومة الاقتصادية، ولا يعتبر بديلاً أو متعارضًا مع قرارات التوطين الصادرة مسبقًا من قبل الوزارة.

                        </Typography>
                        <Typography className="font-medium text-text mb-4">
                            والهدف من هذا الدليل هو توضيح تفاصيل قرار العمل عن بعد ، وبرامج الدعم التي تقدمها منظومة الموارد البشرية والتنمية الاجتماعية، بالإضافة إلى الإجابة على التساؤلات الأكثر شيوعاً.

                        </Typography>


                        {/* part 2 */}
                        <Typography variant="h4" className="text-primary mb-2">
                            مصطلحات وتعريفات
                        </Typography>
                        <Typography className="font-bold text-text">
                            العاملين عن بعد
                        </Typography>
                        <Typography className="font-medium text-text">
                            يقصد بالعمل " عن بعد " أداء العامل لواجباته الوظيفية في غير مكان العمل المعتاد، وذلك بإستخدام أي من وسائل الإتصال وتقنية المعلومات
                        </Typography>
                        <Typography className="font-bold text-text">
                            الاجراءات والعقوبات
                        </Typography>
                        <Typography className="font-medium text-text">
                            الاجراءات المنصوص عليها في هذا الدليل
                        </Typography>
                        <Typography className="font-medium text-text">
                            العقوبات المنصوص عليها في هذا الدليل
                        </Typography>
                        <Typography className="font-bold text-text">
                            الاستثناءات
                        </Typography>
                        <Typography className="font-medium text-text">
                            المهن التي لا يمكن تأدية مهامها عن طريق وسائل التقنية الحديثة، الوظائف التي يشترط مزاولتها داخل مقر المنشأة
                        </Typography>
                        <Typography className="font-bold text-text">
                            المنشأة
                        </Typography>
                        <Typography className="font-medium text-text">
                            كل كيان يديره شخص طبيعي، أو اعتباري، يشغل عاملاً أو أكثر، لقاء أجر أياً كان نوعه
                        </Typography>
                        <Typography className="font-bold text-text">
                            الدعم والتوظيف
                        </Typography>
                        <Typography className="font-medium text-text mb-4">
                            يقصد بها برامج الدعم والتوظيف المقدمة من قبل منظومة الموارد البشرية  لتحفيز القطاع ودعمه
                        </Typography>


                        {/* part 3 */}
                        <Typography variant="h4" className="text-primary mb-2">
                            قرار العمل عن بعد
                        </Typography>
                        <Typography className="font-medium text-text">
                            أولاً: يقصد بالعمل " عن بعد " أداء العامل لواجباته الوظيفية في غير مكان العمل المعتاد ، وذلك بإستخدام أي من وسائل الإتصال وتقنية المعلومات.
                        </Typography>
                        <Typography className="font-medium text-text">
                            ثانيــــاً: يُطبق هذا القرار على جميع المهن والوظائف التي يمكن تأدية مهامها عن طريق التقنية الحديثة من خلال الهاتف، أو البريد الالكتروني، أو وسائل الدردشة، أو وسائل التواصل الاجتماعي والتفاعل المباشر وغيرها.

                        </Typography>
                        <Typography className="font-medium text-text">
                            ثالثـاً: يجوز أن يكون وقت العمل " عن بعد " في غير أوقات العمل المعتمدة بالمنشأة.
                        </Typography>
                        <Typography className="font-medium text-text">
                            رابعًا: يجوز باتفاق أن يتحول العامل الذي يؤدي عمله في مكان العمل الأساسي إلى عامل يؤدي عمله " عن بعد ".

                        </Typography>
                        <Typography className="font-medium text-text">
                            خامسًا: يحق لصاحب العمل توظيف للعمل " عن بعد " بدوام جزئي أو كامل.
                        </Typography>
                        <Typography className="font-medium text-text">
                            سادسًا: يشترط لتوظيف العاملين " عن بعد " توثيق عقودهم عبر البوابة الإلكترونية التي تحددها الوزارة.

                        </Typography>

                    </CardBody>
                </Card>


                <div className='w-full flex justify-center'>
                    <button className="flex items-center rounded-xl bg-gradient-to-r from-info via-secondary to-primary p-2 mt-5 mb-10 hover:animate-bounce">
                        <Typography className="text-white font-semibold mr-2">
                            تحميل
                        </Typography>
                        <MdOutlineFileDownload className="text-white ml-2 mr-1 text-xl" />
                    </button>
                </div>
            </div>


        </div>
    )
}

export default About