import React, { useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import logo from '../images/logo.png'


import img1 from '../images/footer/Groupe 81.png';
import img2 from '../images/footer/Groupe 82.png';
import img3 from '../images/footer/Groupe 83.png';
import img4 from '../images/footer/Groupe 84.png';

const Register = () => {
    const [fullname, setFullname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [workers, setWorkers] = useState('');

    const [isFullnameEmpty, setIsFullnameEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isWorkersEmpty, setIsWorkersEmpty] = useState(false);



    const validateInputs = () => {
        setIsFullnameEmpty(fullname.trim() === '');
        setIsCompanyEmpty(company.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsWorkersEmpty(workers.trim() === '');
        return !isFullnameEmpty && !isCompanyEmpty && !isPhoneEmpty && !isEmailEmpty && !isWorkersEmpty;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='bg-white'>
            <div className="container mx-auto sm:w-2/3 py-10 mb-20">


                <div className='p-2 relative mt-10 flex justify-center'>
                    <img src={logo} className='h-20' />
                </div>

                <div className='p-2 relative mb-20'>
                    <Typography className='px-4 relative sm:text-4xl text-2xl font-semibold text-primary text-center z-10'>
                        طلب الإشتراك
                    </Typography>
                    <div className='sm:h-6 h-4 sm:w-72 w-48 bg-secondary absolute top-7 left-1/2 transform -translate-x-1/2'></div>
                </div>


                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-primary">
                    <div className='flex flex-col col-span-1'>
                        <Typography
                            className='mr-2 mb-2 text-right'
                            variant='paragraph'
                        >
                            اسم المنشاة
                        </Typography>
                        <input type='text' placeholder='اسم المنشاة' onChange={(e) => setFullname(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isFullnameEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    </div>

                    <div className='flex flex-col col-span-1'>
                        <Typography
                            className='mr-2 mb-2 text-right'
                            variant='paragraph'
                        >
                            اسم المسؤول
                        </Typography>
                        <input type='text' placeholder='اسم المسؤول' onChange={(e) => setCompany(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isCompanyEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    </div>

                    <div className='flex flex-col col-span-1'>
                        <Typography
                            className='mr-2 mb-2 text-right'
                            variant='paragraph'
                        >
                            رقم التواصل
                        </Typography>
                        <input type='text' placeholder='رقم التواصل' onChange={(e) => setPhone(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    </div>


                    <div className='flex flex-col col-span-1'>
                        <Typography
                            className='mr-2 mb-2 text-right'
                            variant='paragraph'
                        >
                            البريد الإلكتروني
                        </Typography>
                        <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    </div>


                    <div className='flex flex-col col-span-1'>
                        <Typography
                            className='mr-2 mb-2 text-right'
                            variant='paragraph'
                        >
                            عدد العاملين عن بعد
                        </Typography>
                        <input type='text' placeholder='عدد العاملين عن بعد' onChange={(e) => setWorkers(e.target.value)} className={`md:col-span-2 col-span-1 px-3 py-2 rounded-xl border-2 text-right md:order-4 order-4 placeholder:font-light ${isWorkersEmpty ? 'border-red-400' : 'border-gray-300'}`} />
                    </div>

                    <div className='flex justify-between items-end col-span-1'>
                        <div></div>
                        <button className="flex items-center rounded-xl bg-gradient-to-r from-primary via-secondary to-info p-2 px-10 mt-5 transition-opacity duration-500 ease-out opacity-100 hover:opacity-60">
                            <Typography className="text-white font-semibold mr-2">
                                اشتراك
                            </Typography>
                        </button>
                    </div>


                </div>
            </div>


            <div className='bottom-0 w-full'>
                <div className='px-3'>
                    <hr className="border-info" />
                </div>
                <div className="bg-white w-full p-3">
                    <div className="container mx-auto flex flex-wrap lg:justify-between justify-center items-center">
                        <div className="flex flex-wrap justify-center items-center">
                            <img src={img1} className="h-8 mx-2" alt="footer 1" />
                            <div className="h-8 border border-text mx-4"></div>
                            <img src={img2} className="h-8 mx-2" alt="footer 2" />
                            <img src={img4} className="h-8 mx-2" alt="footer 3" />
                            <img src={img3} className="h-8 mx-2" alt="footer 4" />
                        </div>
                        <p className="text-center text-text md:mt-0 mt-4">
                            © 2024 جميع الحقوق محفوظة لـ موارد الشرق الأوسط
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Register